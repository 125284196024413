import { BsChevronDown, BsSquareFill } from 'react-icons/bs'
import { BiBuildings, BiBriefcase, BiCalendarEvent } from 'react-icons/bi'
import { Container, Stack, Heading, Flex, Box, Text, Button, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';

import { Footer } from '../components'
import styles from '../styles/HomePage.module.css'
import challengeBg from '../assets/challenge.jpg'
import igcLogo from '../assets/white_logo.png'
import smallDots from '../assets/smallsquaredots.svg'
import faqs from '../data/faqs'
import testimonials from '../data/testimonials'


export default function Homepage() {

    let navigate = useNavigate();

    let headerRef = useRef(null);
    let secondRef = useRef(null);
    let thirdRef = useRef(null);
    let fourthRef = useRef(null);

    return (
        <div className={styles.root}>
            <FirstSection currentRef={headerRef} nextRef={secondRef} navigate={navigate} />
            <SecondSection currentRef={secondRef} nextRef={thirdRef} />
            <ThirdSection currentRef={thirdRef} nextRef={fourthRef} navigate={navigate} />
            <FourthSection currentRef={fourthRef} />
            <Footer />
        </div>
    );

}


// First section: headers and titles
function FirstSection({ currentRef, nextRef, navigate }) {
    
    return(

        <Container ref={currentRef} minH='100vh' maxW='100%' position='relative' bgImage={challengeBg} bgSize='cover' p={0}>
            {/* Main title and subtitle */}
            <Stack direction='column' w='100%' h='100vh' p={10} justify='space-evenly' align='center' bg='linear-gradient(174deg, rgba(34,47,70,1) 15%, rgb(213 167 65 / 48%) 100%)'>
                    <img src={igcLogo} style={{height:40,width:'auto'}}/>                
                <Heading textAlign='center' fontWeight={500} fontSize={70} color='#dbdbdb' fontFamily='Poppins' mt={50}>
                    igc challenge <span style={{color:"#d5a741"}}>2023</span>
                </Heading>
                <Heading fontSize={22} textAlign='center' fontWeight={200} color='white' fontFamily='Poppins' lineHeight={9}>
                    A maior competição de M&A do Brasil para estudantes universitários de graduação
                </Heading>
                <Flex direction="column" align="center">
                    <Button onClick={() => navigate('/login')} variant='outline' w={150} h={'45px'} letterSpacing={1} fontSize={18} color='white' _hover={{color:'primary.main', backgroundColor:'#d5a74036'}}>LOGIN</Button>
                    <Button mt={5} variant='link' color='#d5a742c4' disable={true}>Aguarde abertura de inscrições para 2023</Button>
                </Flex>
                <BsChevronDown onClick={()=>nextRef.current.scrollIntoView({behavior: "smooth"})} color='white' fontSize={50} fontWeight={600} cursor='pointer' />
            </Stack>

        </Container>

    );
}

// Second section: about the challenge
function SecondSection({ currentRef, nextRef }) {

    return(
        <Container ref={currentRef} minH='100vh' maxW='100%' p={0} position='relative' bgColor='primary.main' bgSize='cover'>
            <Stack w='100%' minH='100vh' align='center' justify='space-evenly' pt={2} direction='column'>

                <Heading textAlign='center' fontSize={24} lineHeight={9} fontWeight={600} p={5} color='white' fontFamily='Poppins'>
                    Sobre o desafio
                </Heading>

                {/* Content cards */}
                <Stack bgColor='#ffffff94' direction={['column', 'column', 'row']} w='100%' align={['center', 'center', 'flex-start']} justify={['flex-start', 'flex-start', 'space-evenly']} p={5} spacing={8} style={{marginBottom:30}} minH='30vh'>

                    <Box minH={['auto', 'auto', 300]} w={['100%', '80%', '30%']} p={7} direction='column' align='center' justify='flex-start' borderRadius={5}>
                        <BiBriefcase size={25} color='#222f46' />
                        <Heading textAlign='center' fontWeight={500} fontSize={18} color='#222f46' fontFamily='Poppins' mt={2} mb={4}>
                            Challenge
                        </Heading>
                        <Text fontSize={14} textAlign='center' lineHeight={7} color='#383d44b8' fontFamily='Poppins'>
                            Participe da maior competição de M&A do Brasil para estudantes universitários. Os participantes assumirão o papel de profissionais de M&A e irão assessorar uma companhia fictícia em busca de um novo investidor.
                        </Text>
                    </Box>

                    <Box minH={['auto', 'auto', 300]} w={['100%', '80%', '30%']} p={7} direction='column' align='center' justify='flex-start' borderRadius={5}>
                        <BiBuildings size={25} color='#222f46' />
                        <Heading textAlign='center' fontWeight={500} fontSize={18} color='#222f46' fontFamily='Poppins' mt={2} mb={4}>
                            igc partners
                        </Heading>
                        <Text fontSize={14} textAlign='center' lineHeight={7} color='#383d44b8' fontFamily='Poppins'>
                            A igc partners foi fundada em 1997 e é a maior empresa independente de M&A da América Latina! Possui um time de mais de 135 profissionais totalmente dedicados as atividades de M&A. Desde a sua fundação, a igc já assessorou mais de 300 transações.
                        </Text>
                    </Box>

                    <Box minH={['auto', 'auto', 300]} w={['100%', '80%', '30%']} p={7} direction='column' align='center' justify='flex-start' borderRadius={5}>
                        <BiCalendarEvent size={25} color='#222f46' />
                        <Heading textAlign='center' fontWeight={500} fontSize={18} color='#222f46' fontFamily='Poppins' mt={2} mb={4}>
                            Oportunidade
                        </Heading>
                        <Text fontSize={14} textAlign='center' lineHeight={7} color='#383d44b8' fontFamily='Poppins'>
                            Todos os participantes da etapa final poderão receber uma proposta de trabalho da igc partners como analista, estagiário ou estagiário de férias.
                        </Text>
                    </Box>
                    
                </Stack>

                <Heading textAlign='center' fontSize={24} lineHeight={9} fontWeight={600} p={5} color='white' fontFamily='Poppins'>
                    Como participar
                </Heading>

                <Stack bgColor='#ffffff94' direction={['column', 'column', 'row']} w='100%' align={['center', 'center', 'flex-start']} justify={['flex-start', 'flex-start', 'space-evenly']} p={5} spacing={8} style={{marginBottom:30}} minH='20vh'>

                    <Box minH={['auto', 'auto', 300]} w={['100%', '80%', '30%']} p={7} direction='column' align='center' justify='flex-start' borderRadius={5}>
                        <BiBriefcase size={25} color='#222f46' />
                        <Heading textAlign='center' fontWeight={500} fontSize={18} color='#222f46' fontFamily='Poppins' mt={2} mb={4}>
                            Organize um grupo
                        </Heading>
                        <Text fontSize={14} textAlign='center' lineHeight={7} color='#383d44b8' fontFamily='Poppins'>
                            Organize um grupo de 3 integrantes, composto por universitários de graduação, entre o primeiro e o último ano.
                        </Text>
                    </Box>

                    <Box minH={['auto', 'auto', 300]} w={['100%', '80%', '30%']} p={7} direction='column' align='center' justify='flex-start' borderRadius={5}>
                        <BiBuildings size={25} color='#222f46' />
                        <Heading textAlign='center' fontWeight={500} fontSize={18} color='#222f46' fontFamily='Poppins' mt={2} mb={4}>
                            Escolha um líder
                        </Heading>
                        <Text fontSize={14} textAlign='center' lineHeight={7} color='#383d44b8' fontFamily='Poppins'>
                            O representante do grupo será o responsável pela inscrição de todos os participantes e receberá um e-mail com a confirmação de participação do grupo no igc Challenge.
                        </Text>
                    </Box>

                    <Box minH={['auto', 'auto', 300]} w={['100%', '80%', '30%']} p={7} direction='column' align='center' justify='flex-start' borderRadius={5}>
                        <BiCalendarEvent size={25} color='#222f46' />
                        <Heading textAlign='center' fontWeight={500} fontSize={18} color='#222f46' fontFamily='Poppins' mt={2} mb={4}>
                            Realize o desafio
                        </Heading>
                        <Text fontSize={14} textAlign='center' lineHeight={7} color='#383d44b8' fontFamily='Poppins'>
                            O grupo será responsável pela realização das etapas do desafio.
                        </Text>
                    </Box>
                    
                </Stack>

                <BsChevronDown onClick={()=>nextRef.current.scrollIntoView({behavior: "smooth"})} color='white' fontSize={50} fontWeight={600} cursor='pointer' style={{marginBottom:20}} />

            </Stack>
        </Container>
    );
}  

function ThirdSection({ currentRef, navigate }) {

    return(
        <Container ref={currentRef} minH='100vh' maxW='100%' p={0} position='relative' bgColor='#d5a7402e'>
            <Stack w='100%' minH='80vh' align='flex-start' justify='space-evenly' p={5} pt={10} direction={['column', 'row', 'row']}>

                {/* Agenda */}
                <Stack direction='column' w={['95%', '95%', '42%']} align='flex-start' justify='space-evenly' p={5} spacing={8} h='90%'>
                    <Flex direction='row' align='center'>
                        <BsSquareFill size={20} color='#d5a741' />
                        <Heading textAlign='center' fontWeight={500} fontSize={32} color='#222f46' fontFamily='Poppins' mt={4} mb={4} pl={4}>
                            Agenda
                        </Heading>
                    </Flex>
                    <Stack direction='column' align='flex-start' justify='flex-start' spacing={8} w='100%'>
                        <span/>
                        <Text fontSize={18} lineHeight={7} color='#222f46cc' fontFamily='Poppins' borderLeft='3px solid #d5a741' pl={4} style={{marginTop:0, marginLeft:10}}>
                            <b>01/07/2022:&nbsp;</b> Início do período de inscrições
                        </Text>
                        <Text fontSize={18} lineHeight={7} color='#222f46cc' fontFamily='Poppins' borderLeft='3px solid #d5a741' pl={4} style={{marginTop:25, marginLeft:10}}>
                            <b>03/08/2022:&nbsp;</b> Fim do período de inscrições
                        </Text>
                        <Text fontSize={18} lineHeight={7} color='#222f46cc' fontFamily='Poppins' borderLeft='3px solid #d5a741' pl={4} style={{marginTop:25, marginLeft:10}}>
                            <b>08/08/2022:&nbsp;</b> Aula Magna (on-line) e início da primeira etapa do desafio
                        </Text>
                        <Text fontSize={18} lineHeight={7} color='#222f46cc' fontFamily='Poppins' borderLeft='3px solid #d5a741' pl={4} style={{marginTop:25, marginLeft:10}}>
                            <b>15/08/2022:&nbsp;</b> Término da primeira etapa do desafio
                        </Text>
                        <Text fontSize={18} lineHeight={7} color='#222f46cc' fontFamily='Poppins' borderLeft='3px solid #d5a741' pl={4} style={{marginTop:25, marginLeft:10}}>
                            <b>22/08/2022:&nbsp;</b> Anúncio dos aprovados para a segunda etapa e início da mesma
                        </Text>
                        <Text fontSize={18} lineHeight={7} color='#222f46cc' fontFamily='Poppins' borderLeft='3px solid #d5a741' pl={4} style={{marginTop:25, marginLeft:10}}>
                            <b>30/08/2022:&nbsp;</b> Mentorias
                        </Text>
                        <Text fontSize={18} lineHeight={7} color='#222f46cc' fontFamily='Poppins' borderLeft='3px solid #d5a741' pl={4} style={{marginTop:25, marginLeft:10}}>
                            <b>05/09/2022:&nbsp;</b> Término da segunda etapa do desafio
                        </Text>
                        <Text fontSize={18} lineHeight={7} color='#222f46cc' fontFamily='Poppins' borderLeft='3px solid #d5a741' pl={4} style={{marginTop:25, marginLeft:10}}>
                            <b>12/09/2022:&nbsp;</b> Anúncio dos grupos finalistas
                        </Text>
                        <Text fontSize={18} lineHeight={7} color='#222f46cc' fontFamily='Poppins' borderLeft='3px solid #d5a741' pl={4} style={{marginTop:25, marginLeft:10}}>
                            <b>20, 21 e 22/09/2022:&nbsp;</b> Etapa final - presencial
                        </Text>
                    </Stack>
                </Stack>

                {/* Register button and message */}
                <Stack direction='column' w={['95%', '95%', '42%']} align='flex-end' justify='space-evenly' p={5} spacing={8}>
                    <Flex direction='row' align='center'>
                        <BsSquareFill size={20} color='#d5a741' />
                        <Heading textAlign='center' fontWeight={500} fontSize={32} color='#222f46' fontFamily='Poppins' mt={4} mb={4} pl={4}>
                            Premiação
                        </Heading>
                    </Flex>
                    <Text fontSize={18} lineHeight={7} color='#222f46cc' fontFamily='Poppins' style={{marginLeft:10}} textAlign='right' pb={20}>
                        O grupo vencedor receberá o prêmio de <b>R$15.000</b> e poderá ser convidado para fazer parte do time de sucesso da igc.
                    </Text>
                    <Heading textAlign='right' fontSize={24} lineHeight={9} fontWeight={500} color='#222f46cc' fontFamily='Poppins' mt='80px'>
                        Acesse o nosso <b style={{cursor:'pointer',color:'#d5a741'}}><a href='/regulamento_igc_challenge_2022.pdf' target='_blank'>regulamento</a></b> e fique por dentro de todos os detalhes do igc Challenge 2022!
                    </Heading>
                    <Button onClick={() => navigate('/register')} color='primary.main' bgColor='secondary.main' variant='outline' size='lg' w={200} h={55} mt={10} _hover={{bg:'#222f46ba'}} zIndex={50}>
                        Inscreva-se
                    </Button>
                </Stack>

                <img 
                    src={smallDots}
                    style={{
                        height: 200,
                        position: 'absolute',
                        bottom: '15%',
                        right: '10%',
                        opacity: .1
                    }}
                />

            </Stack>
        </Container>
    );
}

function FourthSection({ currentRef }) {

    return(
        <Container ref={currentRef} minH='78vh' maxW='100%' p={0} position='relative' bgColor='#222f46d1' bgSize='cover'>
            <Stack w='100%' minH='78vh' align='flex-start' justify='space-evenly' p={5} pt={8} direction={['column', 'row', 'row']}>
                <Stack direction='column' w={['95%', '95%', '42%']} align='flex-start' justify='space-evenly' pb={10} spacing={8}>
                    <Flex direction='row' align='center'>
                        <BsSquareFill size={20} color='#d5a741' />
                        <Heading textAlign='center' fontWeight={500} fontSize={32} color='white' fontFamily='Poppins' mt={4} mb={4} pl={4}>
                            FAQ
                        </Heading>
                    </Flex>
                    <Accordion allowToggle color='#ffffffad' w='100%'>
                        {faqs.map((faq, index) => (
                            <AccordionItem key={index}>
                                <h2>
                                <AccordionButton fontWeight={600}>
                                    <Box flex='1' textAlign='left'>
                                        {faq.question}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} textAlign='justify'>
                                    {faq.answer}
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </Stack>

                <Stack direction='column' w={['95%', '95%', '42%']} align='flex-start' justify='space-evenly' pb={10} spacing={8}>
                    <Flex direction='row' align='center'>
                        <BsSquareFill size={20} color='#d5a741' />
                        <Heading textAlign='center' fontWeight={500} fontSize={32} color='white' fontFamily='Poppins' mt={4} mb={4} pl={4}>
                            Depoimentos
                        </Heading>
                    </Flex>
                    <Text style={{marginTop:0}} color='#ffffff73' fontFamily='Poppins'>
                        De atuais analistas admitidos através do igc challenge
                    </Text>
                    <Accordion allowToggle color='#ffffffad' w='100%'>
                        {testimonials.map((faq, index) => (
                            <AccordionItem key={index}>
                                <h2>
                                <AccordionButton fontWeight={600}>
                                    <Box flex='1' textAlign='left'>
                                        {faq.name}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} textAlign='justify'>
                                    {faq.testimonial}
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </Stack>
                
            </Stack>
        </Container>
    );

}