import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom'

import Homepage from './pages/Homepage';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Success from './pages/Success';
import Register from './pages/Register';
import Confirm from './pages/Confirm';
import TeamRegister from './pages/TeamRegister'

import FirstStage from './pages/FirstStage';
import Mentoring from './pages/Mentoring';
import SecondStage from './pages/SecondStage';
import FinalStage from './pages/FinalStage';

import { PrivateRoute } from './components';

function Router(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Homepage />}/>
                <Route path="/success" element={<Success />}/>
                <Route path="/login" element={<Login />}/>
                <Route path="/register" element={<Register />}/>
                <Route path="/confirm-signup" element={<Confirm />}/>
                <Route path='/team-register' element={
                    <PrivateRoute>
                        <TeamRegister />
                    </PrivateRoute>
                }/>
                <Route path='/first-stage' element={
                    <PrivateRoute>
                        <FirstStage />
                    </PrivateRoute>
                }/>
                <Route path='/mentoring' element={
                    <PrivateRoute>
                        <Mentoring />
                    </PrivateRoute>
                }/>
                <Route path='/second-stage' element={
                    <PrivateRoute>
                        <SecondStage />
                    </PrivateRoute>
                }/>
                <Route path='/final' element={
                    <PrivateRoute>
                        <FinalStage />
                    </PrivateRoute>
                }/>
                <Route path='*' element={<NotFound />}/>
            </Routes>
        </BrowserRouter>
    )
}

export default Router;
