import { useState } from 'react';
import { Auth, API } from 'aws-amplify';


// Custom hook that handles form submit
const useHandleLogin = (initialState) => {
    
    const [err, setErr] = useState({...initialState});

    const login = async (values, setIsLoading, navigate) => {

        setIsLoading(true)

        // Validate form
        let newErr = {...initialState};
        let hasErr = false;

        if (values.email.length == 0 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
            newErr.email = 'Email inválido';
            hasErr = true;
        }
        if (values.password.length == 0) {
            newErr.password = 'Insira sua senha';
            hasErr = true;
        }

        // If there are errors, set the error state
        if (hasErr) {
            setIsLoading(false)
            return setErr({...newErr});
        } else {
            setErr({...initialState});
        }

        try {
            const user = await Auth.signIn(values.email, values.password);
            // get tem data from api
            const apiName = 'TEAMS';
            const path = '/2022/' + values.email
            const myInit = {};
            const response = await API.get(apiName, path, myInit);
            navigate('/first-stage');
            setIsLoading(false)
        } catch (err) {
            // console.log(err.response)
            if (err.code == 'UserNotConfirmedException') {
                navigate('/confirm-signup')
            } else if (err.code == 'NotAuthorizedException') {
                setErr({...newErr, password: 'Email ou senha inválidos'})
            } else if (err.code == 'UserNotFoundException') {
                setErr({...newErr, password: 'Email ou senha inválidos'})
            } else if (err.code == 'InvalidParameterException') {
                setErr({...newErr, password: 'Email ou senha inválidos'})
            } else if (err.response.status = 404) {
                navigate('/team-register')
            }
            setIsLoading(false)
        }
        
    }

    return [err, login];    

}

export default useHandleLogin;