import { Stack, Flex, Box, Button, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Auth } from 'aws-amplify'

import { useConfigureAmplify, useCreateTeam, useHandleFormChange } from '../hooks'
import { InputField } from '../components'
import styles from '../styles/HomePage.module.css'


export default function TeamRegister() {

    useConfigureAmplify()

    const [isLoading, setIsLoading] = useState(false)
    const [team, handleChange] = useHandleFormChange({
        login_email : '',
        team_name : '',
    })
    let member = {
        email: '',
        name: '',
        phone: '',
        university: '',
        course: '',
        semester: '',
        year_of_graduation: '',
        work_experience: '',
        linkedin: ''
    }
    const [err, createTeam] = useCreateTeam({
        team_name: '',
        login_email: '',
        members: [{...member}, {...member}, {...member}]
    })
    const [m1, handleM1] = useHandleFormChange({...member})
    const [m2, handleM2] = useHandleFormChange({...member})
    const [m3, handleM3] = useHandleFormChange({...member})


    const navigate = useNavigate()

    return (
        <div className={styles.root}>
            <Flex minH='100vh' maxW='100%' p={0} position='relative' bgColor='secondary.main' align='center' justify='center' direction={'column'}>
                <img src='/white_logo.png' alt='logo' width='60px' style={{marginBottom:20, marginTop:'60px'}}/>
                <Heading fontSize={32} mt={'20px'} fontFamily='Poppins' fontWeight={600} textAlign='center' pb={5} color='primary.main' borderBottom="1px solid #d5a741">Inscrição do grupo</Heading>
                <Stack direction='column' w={['90%', '80%', '80%']}  minH='auto' p={[4,6,10]} pb={10} justify='center' align='center' bgColor='#efefef' borderRadius={10} boxShadow='dark-lg' mt={10}>
                    <Heading fontSize={26} fontFamily='Poppins' fontWeight={600} textAlign='center' pb={5} color='secondary.main'>Nome do grupo</Heading>
                    <InputField 
                        name='team_name' 
                        placeholder='Nome do grupo'
                        disabled={isLoading}
                        onChange={handleChange}
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.team_name}</Text>
                </Stack>
                <Participant err={err.members[0]} handleChange={handleM1} n={1} isLoading={isLoading} />
                <Participant err={err.members[1]} handleChange={handleM2} n={2} isLoading={isLoading} />
                <Participant err={err.members[2]} handleChange={handleM3} n={3} isLoading={isLoading} />
                <Button
                    fontFamily={'heading'}
                    mt={20}
                    mb={20}
                    w={['90%', '300px', '300px']}
                    bgGradient="linear(to-r, grey,primary.main)"
                    color={'white'}
                    _hover={{
                        bgGradient: 'linear(to-r, grey, primary.main)',
                        boxShadow: 'xl',
                    }}
                    onClick={() => createTeam({...team, members:[m1, m2, m3]}, setIsLoading, navigate)}
                    isLoading={isLoading}
                    loadingText='Enviando'
                    type='submit'
                >
                    Enviar
                </Button>
            </Flex>
        </div>
    );

}



function Participant({ isLoading, n, handleChange, err }) {


    return(
        <Stack direction='column' w={['90%', '80%', '80%']}  minH='auto' p={[4,6,10]} pb={10} justify='center' align='center' bgColor='#efefef' borderRadius={10} boxShadow='dark-lg' mt={10}>
            <Heading fontSize={26} fontFamily='Poppins' fontWeight={600} textAlign='center' pb={5} color='secondary.main'>Participante {n}</Heading>
            <Box mt={10} minW='90%'>
                <Stack spacing={4}>

                    <InputField
                        placeholder="Email"
                        name="email"
                        disabled={isLoading}
                        onChange={handleChange}
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.email}</Text>
                    
                    <InputField
                        placeholder="Nome completo"
                        name="name"
                        disabled={isLoading}
                        onChange={handleChange}
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.name}</Text>
                    
                    <InputField
                        placeholder="Linkedin"
                        name="linkedin"
                        disabled={isLoading}
                        onChange={handleChange}
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.linkedin}</Text>
                        
                    <InputField
                        placeholder="Telefone"
                        name="phone"
                        disabled={isLoading}
                        onChange={handleChange}
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.phone}</Text>

                    <Flex justify='space-between' style={{marginTop:1}}>
                        <Flex direction='column' width='48%'>
                            <InputField
                                placeholder="Universidade"
                                name="university"
                                disabled={isLoading}
                                onChange={handleChange}
                            />
                            <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.university}</Text>
                        </Flex>
                        <Flex direction='column' width='48%'>
                            <InputField
                                placeholder="Curso"
                                name="course"
                                disabled={isLoading}
                                onChange={handleChange}
                            />
                            <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.course}</Text>
                        </Flex>
                    </Flex>

                    <Flex justify='space-between' style={{marginTop:1}}>
                        <Flex direction='column' width='48%'>
                            <InputField
                                placeholder="Semestre"
                                name="semester"
                                disabled={isLoading}
                                onChange={handleChange}
                                type="number"
                            />
                            <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.semester}</Text>
                        </Flex>
                        <Flex direction='column' width='48%'>
                            <InputField
                                placeholder="Ano previsto para graduação"
                                name="year_of_graduation"
                                disabled={isLoading}
                                onChange={handleChange}
                                type="number"
                            />
                            <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.year_of_graduation}</Text>
                        </Flex>
                    </Flex>

                    <InputField
                        placeholder="Experiências anteriores"
                        name="work_experience"
                        disabled={isLoading}
                        onChange={handleChange}
                        size="lg"
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.work_experience}</Text>
                       
                </Stack>
            </Box>
        </Stack>
    );

}