import { Stack, Flex, Box, Button, Text, Heading, Alert, AlertIcon } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { useHandleFormChange, useConfigureAmplify, useCognitoSignup, useFetch } from '../hooks'
import { InputField, Loading } from '../components'
import styles from '../styles/HomePage.module.css'


export default function Register() {

    useConfigureAmplify()

    let [status, data] = useFetch("CHALLENGE_EDITION", '/edition/2022') //TODO: Remove hardcoded year
    let navigate = useNavigate()

    if (status == "fetching") {
        return <Loading w="100vv" h="100vh"/>
    } else if (data && data.active_stage && data.active_stage != "registration_open") {
        alert("As inscrições não estão abertas, verifique o calendário")
        navigate("/")
        return null
    }

    return (
        <div className={styles.root}>
            <Signup navigate={navigate} />
        </div>
    );

}


// First Step component
// Creates a cognito user
function Signup({ navigate }) {

    const [values, handleChange] = useHandleFormChange({
        email: '',
        password: '',
        confirm: '',
    })

    const [err, signup] = useCognitoSignup({
        email : false,
        password : false,
        confirm : false
    })

    const [isLoading, setIsLoading] = useState(false)

    return(
        <Flex minH='100vh' maxW='100%' p={0} position='relative' bgColor='secondary.main' align='center' justify='center' direction={'row'}>
            <Stack direction='column' w={['90%', '80%', '35%']}  minH='auto' p={10} justify='center' align='center' bgColor='#ebdfc1' borderRadius={20} boxShadow='xl'>
                <Heading fontSize={26} fontFamily='Poppins' fontWeight={600} textAlign='center' pb={5} color='secondary.main'>Inscrição igc Challenge 2022</Heading>
                <img src='/logo.png' alt='logo' width='60px' style={{marginBottom:20}}/>
                <Box mt={10} minW='90%'>
                    <Stack spacing={4}>
                        <InputField
                            placeholder="Email"
                            name="email"
                            disabled={isLoading}
                            onChange={handleChange}
                        />
                        <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.email}</Text>
                        <InputField
                            placeholder="Senha"
                            type="password"
                            name="password"
                            disabled={isLoading}
                            onChange={handleChange}
                        />
                        <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.password}</Text>
                        <InputField
                            placeholder="Confirmar senha"
                            type="password"
                            name="confirm"
                            disabled={isLoading}
                            onChange={handleChange}
                        />
                        <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.confirm}</Text>
                    </Stack>
                    <Button
                        fontFamily={'heading'}
                        mt={8}
                        w={'full'}
                        bgGradient="linear(to-r, secondary.main,primary.main)"
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear(to-r, secondary.main, primary.main)',
                            boxShadow: 'xl',
                        }}
                        onClick={() => signup(values, setIsLoading, navigate)}
                        isLoading={isLoading}
                        loadingText='Enviando'
                        type='submit'
                    >
                        Enviar
                    </Button>
                </Box>
            </Stack>
        </Flex>
    );

}