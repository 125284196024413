import { useEffect } from 'react';
import Amplify, { auth0SignInButton } from 'aws-amplify';


// Custom hook to configure Amplify
// This is a custom hook that handles the configuration of Amplify
const useConfigureAmplify = () => {

    useEffect(() => {
        
        let region =                    process.env.REACT_APP_REGION
        let identityPoolId =            (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID_DEV : process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID_PROD)
        let bucket =                    (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_RECRUITMENT_S3_BUCKET_DEV : process.env.REACT_APP_RECRUITMENT_S3_BUCKET_PROD)
        let userPoolId =                (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_COGNITO_USER_POOL_ID_DEV : process.env.REACT_APP_COGNITO_USER_POOL_ID_PROD)
        let clientId =                  (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_COGNITO_CLIENT_ID_DEV : process.env.REACT_APP_COGNITO_CLIENT_ID_PROD)
        let teamsApi =                  (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_TEAMS_API_URL_DEV : process.env.REACT_APP_TEAMS_API_URL_PROD)
        let apiChallengeEditionUrl =    (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_API_CHALLENGE_EDITION_DEV : process.env.REACT_APP_API_CHALLENGE_EDITION_PROD)
        let apiChallengeSchedulerUrl =  (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_API_CHALLENGE_SCHEDULER_DEV : process.env.REACT_APP_API_CHALLENGE_SCHEDULER_PROD)

        let storage = {
            AWSS3: {
                bucket: bucket, 
                region: region, 
            }
        }

        let api = {
            endpoints: [
                {
                    name: "TEAMS",
                    endpoint: teamsApi
                },
                {
                    name: "CHALLENGE_EDITION",
                    endpoint: apiChallengeEditionUrl
                },
                {
                    name: "CHALLENGE_SCHEDULER",
                    endpoint: apiChallengeSchedulerUrl
                },
            ]
        }

        let auth = {
            region: region,
            userPoolId: userPoolId,
            userPoolWebClientId: clientId,
            identityPoolId: identityPoolId
        }

        Amplify.configure({
            Auth: auth,
            Storage: storage,
            API: api
        });
    
    }, []);
    
    return true;
};

export default useConfigureAmplify;