import { Stack, Flex, Box, Button, Text, Heading } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { useHandleFormChange, useConfigureAmplify, useConfirmSignup } from '../hooks'
import { InputField } from '../components'
import styles from '../styles/HomePage.module.css'


export default function Confirm() {

    useConfigureAmplify()

    return (
        <div className={styles.root}>
            <Form />
        </div>
    );

}



function Form() {

    const [values, handleChange] = useHandleFormChange({
        email: '',
        code: '',
    })

    const [err, confirm] = useConfirmSignup({
        email : false,
        code : false,
    })

    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    return(
        <Flex minH='100vh' maxW='100%' p={0} position='relative' bgColor='secondary.main' align='center' justify='center' direction={'row'}>
            <Stack direction='column' w={['90%', '80%', '35%']}  minH='auto' p={10} justify='center' align='center' bgColor='#ebdfc1' borderRadius={20} boxShadow='xl'>
                <Heading fontSize={26} fontFamily='Poppins' fontWeight={600} textAlign='center' pb={5} color='secondary.main'>Confirmar conta</Heading>
                <img src='/logo.png' alt='logo' width='60px' style={{marginBottom:20}}/>
                <Box mt={10} minW='90%'>
                    <Stack spacing={4}>
                        <InputField
                            placeholder="Email"
                            name="email"
                            disabled={isLoading}
                            onChange={handleChange}
                        />
                        <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.email}</Text>
                        <InputField
                            placeholder="Código de confirmação"
                            name="code"
                            disabled={isLoading}
                            onChange={handleChange}
                        />
                        <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.code}</Text>
                    </Stack>
                    <Button
                        fontFamily={'heading'}
                        mt={8}
                        w={'full'}
                        bgGradient="linear(to-r, secondary.main,primary.main)"
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear(to-r, secondary.main, primary.main)',
                            boxShadow: 'xl',
                        }}
                        onClick={() => confirm(values, setIsLoading, navigate)}
                        isLoading={isLoading}
                        loadingText='Enviando'
                        type='submit'
                    >
                        Enviar
                    </Button>
                </Box>
            </Stack>
        </Flex>
    );

}