import { useState } from 'react';
import { Auth, API } from 'aws-amplify';


// Custom hook that handles form submit
const useCreateTeam = (initialState) => {
    
    const [err, setErr] = useState({...initialState});

    const createTeam = async (values, setIsLoading, navigate) => {

        setIsLoading(true)
        let hasErr = false;
        let newErr = {...initialState};

        if (!values.team_name) {
            hasErr = true;
            newErr.team_name = 'Insira um nome válido';
        }
        
        for (let i = 0; i < values.members.length; i++) {
            if (!values.members[i].email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.members[i].email)) {
                hasErr = true;
                newErr.members[i].email = 'Insira um email válido';
            }
            if (values.members[i].name.length < 10) {
                hasErr = true;
                newErr.members[i].name = 'Insira um nome válido';
            }
            if (values.members[i].phone.length < 10) {
                hasErr = true;
                newErr.members[i].phone = 'Insira um telefone válido';
            }
            if (!values.members[i].university) {
                hasErr = true;
                newErr.members[i].university = 'Insira uma universidade válida';
            }
            if (!values.members[i].course) {
                hasErr = true;
                newErr.members[i].course = 'Insira um curso válido';
            }
            if (parseInt(values.members[i].semester) < 1 || parseInt(values.members[i].semester) > 12 || isNaN(parseInt(values.members[i].semester))) {
                hasErr = true;
                newErr.members[i].semester = 'Insira um semestre válido';
            }
            if (parseInt(values.members[i].year_of_graduation) < 2022 || parseInt(values.members[i].year_of_graduation) > 2030 || isNaN(parseInt(values.members[i].year_of_graduation))) {
                hasErr = true;
                newErr.members[i].year_of_graduation = 'Insira um ano válido';
            }
            if (!values.members[i].work_experience) {
                hasErr = true;
                newErr.members[i].work_experience = 'Esse campo não pode estar em branco';
            }
            if (values.members[i].linkedin.length < 10) {
                hasErr = true;
                newErr.members[i].linkedin = 'Insira o endereço do seu perfil no Linkedin';
            }
        }

        if (hasErr) {
            setIsLoading(false)
            return setErr({...newErr});
        } else {
            setErr({...initialState});
        }

        try {

            let user = await Auth.currentAuthenticatedUser();
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : {
                    team_name: values.team_name,
                    members: values.members,
                    login_email: user.attributes.email
                }
            }

            let response = await API.post('TEAMS', '/create', myInit);

            console.log(response)
            setIsLoading(false)
            alert('Cadastro realizado com sucesso!')
            navigate('/first-stage')
        } catch (err) {
            if (err.response.status == 422) {
                setIsLoading(false)
                alert("Erro de validação, verifique se os emails dos membros estão corretos e diferentes")
            } else if (err.response.data == "The following users are already registered: bhkfazano@gmail.com, bruno.koga@igcp.com.br") {
                alert(err.response.data)
            }
            setIsLoading(false)
        }
        
    }

    return [err, createTeam];    

}

export default useCreateTeam;