import { useState } from 'react';


// Custom hook that handles form changes
const useHandleFormChange = (initialState) => {

    const [values, setValues] = useState({...initialState});

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        if (e.target.type === 'file') {
            setValues({
                ...values,
                [name]: e.target.files[0],
                file_name: e.target.files[0].name,
            });
        } else {
            setValues({
                ...values,
                [name]: value
            });
        }
    }

    return [values, handleChange];    

}

export default useHandleFormChange;