import { Input, Textarea, Text } from '@chakra-ui/react';

export default function InputField({ size, placeholder, name, onChange, type, disabled, bg, value, label }) {
    
    if (size != 'lg') {
        return(
            <>
                {label ? <Text mt={1} fontWeight={600} fontSize={12}>{label}</Text> : null}
                <Input
                    size={size || 'md'}
                    placeholder={placeholder}
                    value={value}
                    bg={bg || '#ffffff'}
                    border={0}
                    color={'gray.500'}
                    fontSize={12}
                    fontFamily='Poppins'
                    _placeholder={{
                        fontSize:12,
                        color: 'gray.500',
                        fontFamily: 'Poppins',
                    }}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    type={type || 'text'}
                    style={{marginTop: label ? 5 : 10}}
                />
            </>
        );

    } else if (size === 'lg') {
        return(
            <>
                {label ? <Text mt={1} ml={2} fontWeight={600} fontSize={12}>{label}</Text> : null}
                <Textarea
                    placeholder={placeholder}
                    value={value}
                    bg={bg || '#ffffff'}
                    border={0}
                    color={'gray.500'}
                    fontSize={12}
                    fontFamily='Poppins'
                    _placeholder={{
                        fontSize:12,
                        color: 'gray.500',
                        fontFamily: 'Poppins'
                    }}
                    name={name}
                    onChange={onChange}
                    disabled={disabled}
                    style={{marginTop: label ? 5 : 10}}
                />
            </>

        );
    }

}