import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';


// Custom hook to fetch data from the API
// @param {string} endpoint - the path to the API endpoint
const useFetch = (api, endpoint, email=false) => {

    const [status, setStatus] = useState('idle');
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!endpoint) return;

        const fetchData = async () => {
            setStatus('fetching');
            try {
                let myInit = {}
                try {
                    myInit = { 
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                } catch (error) {
                    console.log(error)
                }
                var endpoint_ = endpoint
                if (email) {
                    let userEmail = (await Auth.currentAuthenticatedUser()).attributes.email
                    endpoint_ = endpoint + (email ? userEmail  : '')
                }
                const result = await API.get(api, endpoint_, myInit);
                setData(result);
                setStatus('idle');
            } catch (error) {
                console.log(error)
                setStatus('idle');
                if (error.response.status == 404) {
                    setData([]);
                } else {
                    alert('Erro ao carregar os dados, tente novamente mais tarde.');
                }
            }
        };

        fetchData();
        
    }, [endpoint]);
    
    return [ status, data ];
};

export default useFetch;