
import { Heading, Flex, Text, Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { BsSquareFill } from 'react-icons/bs'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { Storage } from 'aws-amplify'
import { useState } from 'react'

import { Header, SideBar, Loading, InputField, NotStarted } from '../components'
import { useHandleSecondStage } from '../hooks'

export default function SecondStage() {

    let navigate = useNavigate()

    let [loading, setLoading] = useState(false)
    let [status, {edition, team}, imLink, valuationLink, handleChange, handleSubmit, fileSubmit, file] = useHandleSecondStage()
    
    return(
        <Flex minH='100vh' w='100%' p={10} position='relative' bgColor='#efe8d8' align='center' justify='flex-start' direction={'column'} pl={280} pt={85}>
            <SideBar navigate={navigate} />
            <Header title="Segunda Etapa: IM e Valuation" />

            {status == 'fetching' ? <Loading h='100%' /> :

                edition && parseInt(edition.active_stage.split("-")[0]) >= 2  ?


                <Flex w='100%' h='auto' direction={['column', 'column', 'row']} align='flex-start' justify='space-between' pt={5} pb={2} pl={8}>

                    <Flex direction='column' w={['90%', '90%', '48%']} mb={10}>
                        <Flex direction="row" align="center" pl={4}>
                            <BsSquareFill color="#d5a741" />
                            <Heading fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} pl={4}>Instruções e Materiais</Heading>
                        </Flex>
                        <Flex bgColor="#ffffffa8" w="95%" mt={5} minH="220px" borderRadius={10} boxShadow="xl" p={5} direction="column" align='center'>
                            <Text fontSize={14} color="#8d8c8c" lineHeight="29px" mt={2} fontFamily="Poppins" textAlign={"justify"}>
                                <li style={{marginLeft:10, marginBottom:10}}>O grupo deverá postar os materiais da Tese de Investimento e Valuation até o dia 05/09/22 às 18h.</li>
                                <li style={{marginLeft:10, marginBottom:10}}>Etapa não presencial, obrigatória e eliminatória.</li>
                                <li style={{marginLeft:10, marginBottom:10}}>A entrega consiste no envio de dois vídeos de até 3 minutos cada (um com a tese de investimento e outro contendo a apresentação do valuation), além do modelo financeiro elaborado pelo grupo.</li>
                                <li style={{marginLeft:10, marginBottom:10}}>O vídeo deverá estar disponível em alguma plataforma on-line de vídeo (Youtube, Vimeo e similares), sob pena de eliminação.</li>
                                <li style={{marginLeft:10, marginBottom:10}}>A equipe pode optar por enviar apenas um vídeo de até 6 minutos englobando as duas tarefas. Nesse caso, <b>basta gravar apenas um vídeo e enviar o mesmo link para as duas tarefas.</b></li>
                                <li style={{marginLeft:10, marginBottom:10}}>Para verificar se seu envio foi realizado com sucesso, basta clicar em "VER ENVIO".</li>
                                <li style={{marginLeft:10, marginBottom:10}}>Vídeos apenas com slides e sem narração/voz serão desconsiderados e o grupo estará automaticamente eliminado.</li>
                                <li style={{marginLeft:10, marginBottom:10}}>Caso o vídeo tenha mais de 6 minutos (no caso de vídeo único) ou mais de 3 minutos (vídeos separados), o grupo será eliminado.</li>
                                <li style={{marginLeft:10, marginBottom:10}}>O envio do modelo financeiro pode ser feito mais de uma vez, apenas o ultimo upload será considerado.</li>
                                <li style={{marginLeft:10, marginBottom:10}}>Todos os grupos serão minunciosamente avaliados e notificados se foram aprovados ou não para próxima etapa.</li>
                                <li style={{marginLeft:10, marginBottom:10}}>O arquivo disponibilizado para download abaixo contém as premissas a serem utilizadas para o modelo financeiro.</li>
                            </Text>
                            <Button
                                fontFamily={'poppins'}
                                mt={8}
                                w={'60%'}
                                bgColor="#3d4758d6"
                                color={'white'}
                                _hover={{
                                    boxShadow: 'xl',
                                }}
                                onClick={async () => {
                                    const url = await Storage.get('ChallengeEtapa2.xlsx');
                                    window.open(url, "_blank");
                                }}
                                loadingText='Enviando'
                                type='submit'
                            >
                                Download
                            </Button>
                        </Flex>
                    </Flex>

                    <Flex direction='column' w={['90%', '90%', '48%']} mb={10}>

                        <Flex direction="row" align="center" pl={4}>
                            <BsSquareFill color="#d5a741" />
                            <Heading fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} pl={4}>Video IM</Heading>
                        </Flex>                    
                        <Flex bgColor="#ffffffa8" w="95%" mt={5} borderRadius={10} boxShadow="xl" p={10} pt={5} direction="row" align="center" justify="space-between" mb={10}>
                            <InputField
                                placeholder="Link do Video IM"
                                value={imLink}
                                name="link"
                                disabled={loading || edition.active_stage != "2-mentoring_started"}
                                onChange={(e) => handleChange(e, "im")}
                                w="60%"
                            />
                            <Flex direction="row" align="flex-start" justify="space-between" w="38%" ml={4}>
                                <Button
                                    fontFamily={'poppins'}
                                    w={'48%'}
                                    mt="10px"
                                    bgGradient="linear(to-r, secondary.main,primary.main)"
                                    color={'white'}
                                    _hover={{
                                        bgGradient: 'linear(to-r, secondary.main, primary.main)',
                                        boxShadow: 'xl',
                                    }}
                                    onClick={() => handleSubmit("im", setLoading)}
                                    loadingText='Enviando'
                                    type='submit'
                                    disabled={loading || !imLink || edition.active_stage != "2-mentoring_started" || !team.mentoring_time}
                                >
                                    Enviar
                                </Button>
                                <Button
                                    fontFamily={'poppins'}
                                    w={'48%'}
                                    mt="10px"
                                    bgColor="#3d4758d6"
                                    color={'white'}
                                    _hover={{
                                        boxShadow: 'xl',
                                    }}
                                    onClick={() => team.second_stage.im_link ? window.open(team.second_stage.im_link, '_blank') : alert("Nenhum vídeo foi enviado")}
                                    loadingText='Enviando'
                                    disabled={loading || !team || !team.second_stage || !team.second_stage.im_link}
                                >
                                    Ver
                                </Button>
                            </Flex>
                        </Flex>

                        <Flex direction="row" align="center" pl={4}>
                            <BsSquareFill color="#d5a741" />
                            <Heading fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} pl={4}>Video Valuation</Heading>
                        </Flex>                    
                        <Flex bgColor="#ffffffa8" w="95%" mt={5} borderRadius={10} boxShadow="xl" p={10} pt={5} direction="row" align="center" justify="space-between" mb={10}>
                            <InputField
                                placeholder="Link do Video Valuation"
                                value={valuationLink}
                                name="link"
                                disabled={loading || edition.active_stage != "2-mentoring_started"}
                                onChange={(e) => handleChange(e, "valuation")}
                                w="60%"
                            />
                            <Flex direction="row" align="flex-start" justify="space-between" w="38%" ml={4}>
                                <Button
                                    fontFamily={'poppins'}
                                    w={'48%'}
                                    mt="10px"
                                    bgGradient="linear(to-r, secondary.main,primary.main)"
                                    color={'white'}
                                    _hover={{
                                        bgGradient: 'linear(to-r, secondary.main, primary.main)',
                                        boxShadow: 'xl',
                                    }}
                                    onClick={() => handleSubmit("valuation", setLoading)}
                                    loadingText='Enviando'
                                    type='submit'
                                    disabled={loading || edition.active_stage != "2-mentoring_started" || !valuationLink || !team.mentoring_time}
                                >
                                    Enviar
                                </Button>
                                <Button
                                    fontFamily={'poppins'}
                                    w={'48%'}
                                    mt="10px"
                                    bgColor="#3d4758d6"
                                    color={'white'}
                                    _hover={{
                                        boxShadow: 'xl',
                                    }}
                                    onClick={() => team.second_stage.valuation_link ? window.open(team.second_stage.valuation_link, '_blank') : alert("Nenhum vídeo foi enviado")}
                                    loadingText='Enviando'
                                    disabled={loading || !team || !team.second_stage || !team.second_stage.valuation_link}
                                >
                                    Ver
                                </Button>
                            </Flex>
                        </Flex>

                        <Flex direction="row" align="center" pl={4}>
                            <BsSquareFill color="#d5a741" />
                            <Heading fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} pl={4}>Modelo Financeiro</Heading>
                        </Flex>                    
                        <Flex bgColor="#ffffffa8" w="95%" mt={5} borderRadius={10} boxShadow="xl" p={10} pt={5} direction="row" align="center" justify="space-between" mb={10}>
                            <input
                                style={{marginTop:'10px', fontSize:12}}
                                type="file"
                                disabled={loading || edition.active_stage != "2-mentoring_started"}
                                onChange={e => handleChange(e.target.files[0], "file")}
                            />
                            <Flex direction="row" align="flex-start" justify="flex-end" w="38%" ml={4}>
                                <Button
                                    fontFamily={'poppins'}
                                    w={'48%'}
                                    mt="10px"
                                    bgGradient="linear(to-r, secondary.main,primary.main)"
                                    color={'white'}
                                    _hover={{
                                        bgGradient: 'linear(to-r, secondary.main, primary.main)',
                                        boxShadow: 'xl',
                                    }}
                                    onClick={() => fileSubmit(setLoading)}
                                    loadingText='Enviando'
                                    type='submit'
                                    disabled={loading || edition.active_stage != "2-mentoring_started" || !team.mentoring_time || !file}
                                >
                                    Enviar
                                </Button>
                            </Flex>
                        </Flex>

                    </Flex>

                </Flex>

                :
                <NotStarted w='100%' h='100%' />
            }
        </Flex>
    );

}
