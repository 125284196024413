
import { Heading, Flex, Text, Button, Select } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { BsSquareFill } from 'react-icons/bs'
import { useState } from 'react'

import { Header, SideBar, MultipleInput, NotStarted, Loading } from '../components'
import { useHandleFinalStage } from '../hooks'

export default function Mentoring() {

    let navigate = useNavigate()

    let [loading, setLoading] = useState(false)
    let [status, { team, edition }, finalTime, chosenTime, disableTime, setChosenTime, submitFinalTime] = useHandleFinalStage()

    return(
        <Flex minH='100vh' w='100%' p={10} position='relative' bgColor='#efe8d8' align='center' justify='flex-start' direction={'column'} pl={280} pt={85}>
            <SideBar navigate={navigate} />
            <Header title="Etapa Final" />

            {status == 'fetching' ? <Loading h='100%' /> :

                edition && edition.active_stage == "2-stage_ended" && team.status == "approved_2" ?

                <Flex w='100%' h='auto' direction={['column', 'column', 'row']} align='flex-start' justify='center' pt={5} pb={2} pl={8}>

                    <Flex direction='column' w={['90%', '90%', '90%']} mb={10}>
                        <Flex direction="row" align="center" pl={4}>
                            <BsSquareFill color="#d5a741" />
                            <Heading fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} pl={4}>Agendamento da Etapa Final</Heading>
                        </Flex>                    
                        <Flex bgColor="#ffffffa8" w="95%" mt={5} minH="220px" borderRadius={10} boxShadow="xl" p={5} direction="column">
                            <Text mb={6} fontSize={14} color="#8d8c8c" lineHeight="29px" fontFamily="Poppins" textAlign={"justify"}>
                                O agendamento automático da etapa final está desabilitado. A organização do evento irá entrar em contato com você para agendar a etapa final.
                            </Text>
                        </Flex>
                    </Flex>

                </Flex>

                :
                <NotStarted w='100%' h='100%' />
            }
        </Flex>
    );

}
