import { useEffect, useState } from 'react';
import { Auth, API } from 'aws-amplify';

// Custom hook that handles change video teaser link
const useHandleMentoringStage = () => {
    
    let [status, setStatus] =                   useState('idle');
    let [data, setData] =                       useState([]);
    let [finalTime, setFinalTime] =     useState('');
    let [chosenTime, setChosenTime] =           useState('');
    let [disableTime, setDisableTime] =         useState(false);


    useEffect(()=>{

        const fetchData = async () => {

            setStatus('fetching');

            try {

                let myInit = {}
                try {
                    myInit = { 
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                } catch (error) {
                    console.log(error)
                }

                let userEmail = (await Auth.currentAuthenticatedUser()).attributes.email
                let endpoint = "/2022/" + userEmail
                let result = await API.get("TEAMS", endpoint, myInit);
                setData(result);
                let schedules = await API.get("CHALLENGE_SCHEDULER", "/schedule/hr", myInit);
                setFinalTime(schedules)
                setChosenTime(schedules[0].time)

                if (result && result.team.final_time) {
                    setDisableTime(true);
                    setChosenTime(result.team.final_time);
                }

                setStatus('idle');

            } catch (error) {
                console.log(error)
                setStatus('idle');
                if (error.resonse.status == 404) {
                    setData([]);
                } else {
                    alert('Erro ao carregar os dados, tente novamente mais tarde.');
                }
            }
        };

        fetchData();

    }, [])

    const submitFinalTime = async (setLoading) => {
        setLoading(true)

        let body = {
            team: data.team,
            mentor: 'hr',
            time: chosenTime,
        }

        try {
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }
            let response = await API.post('CHALLENGE_SCHEDULER', '/schedule/assignFinal', myInit);
            setLoading(false)
            alert('Horário agendado com sucesso.')
            window.location.reload();
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }  

    }


    return [status, data, finalTime, chosenTime, disableTime, setChosenTime, submitFinalTime];    

}

export default useHandleMentoringStage;