import { Heading, Flex, Text, Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Storage } from 'aws-amplify'
import { BsSquareFill } from 'react-icons/bs'
import { useState } from 'react'

import { Header, SideBar, Loading, InputField, NotStarted } from '../components'
import { useHandleFirstStage } from '../hooks'

export default function FirstStage() {

    let navigate = useNavigate()

    let [loading, setLoading] = useState(false)
    let [status, { team, edition }, teaserLink, handleChange, submit] = useHandleFirstStage()

    return(
        <Flex minH='100vh' w='100%' p={10} position='relative' bgColor='#efe8d8' align='center' justify='flex-start' direction={'column'} pl={280} pt={85}>
            <SideBar navigate={navigate} />
            <Header title="Primeira Etapa: Video Teaser" />

            {status == 'fetching' ? <Loading h='100%' /> :

                edition && parseInt(edition.active_stage.split("-")[0]) >= 1 ?

                <Flex w='100%' h='auto' direction={['column', 'column', 'row']} align='flex-start' justify='space-between' pt={5} pb={2} pl={8}>

                    <Flex direction='column' w={['90%', '90%', '48%']} mb={10}>
                        <Flex direction="row" align="center" pl={4}>
                            <BsSquareFill color="#d5a741" />
                            <Heading fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} pl={4}>Instruções</Heading>
                        </Flex>
                        <Flex bgColor="#ffffffa8" w="95%" mt={5} minH="220px" borderRadius={10} boxShadow="xl" p={5} direction="column" align='center'>
                            <Text fontSize={14} color="#8d8c8c" lineHeight="29px" mt={2} fontFamily="Poppins" textAlign={"justify"}>
                                Veja no PDF as intruções para o case do desafio. Você deve gravar um vídeo e submetê-lo ao youtube. Depois, envie o link do vídeo no formulário ao lado.
                            </Text>
                            <Button
                                fontFamily={'poppins'}
                                mt={8}
                                w={'60%'}
                                bgColor="#3d4758d6"
                                color={'white'}
                                _hover={{
                                    boxShadow: 'xl',
                                }}
                                onClick={async () => {
                                    const url = await Storage.get('PetCo.zip');
                                    window.open(url, "_blank");
                                }}
                                loadingText='Enviando'
                                type='submit'
                            >
                                Enunciado
                            </Button>
                        </Flex>
                    </Flex>

                    <Flex direction='column' w={['90%', '90%', '48%']} mb={10}>
                        <Flex direction="row" align="center" pl={4}>
                            <BsSquareFill color="#d5a741" />
                            <Heading fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} pl={4}>Envio</Heading>
                        </Flex>                    
                        <Flex bgColor="#ffffffa8" w="95%" mt={5} minH="220px" borderRadius={10} boxShadow="xl" p={10} pt={5} direction="column">
                            <Text mb={2} fontSize={14} color="#8d8c8c" lineHeight="29px" mt={2} fontFamily="Poppins" textAlign={"justify"}>
                                Envie abaixo o link do video teaser gravado. Caso deseje verificar o ultimo envio, basta clicar em "ver envio".
                            </Text>
                            <InputField
                                placeholder="Link do Video Teaser"
                                name="link"
                                value={teaserLink}
                                disabled={loading || edition.active_stage != '1-submit_open'}
                                onChange={handleChange}
                                w="80%"
                            />
                            <Flex direction="row" align="center" justify="space-evenly">
                                <Button
                                    fontFamily={'poppins'}
                                    mt={8}
                                    w={'45%'}
                                    bgGradient="linear(to-r, secondary.main,primary.main)"
                                    color={'white'}
                                    _hover={{
                                        bgGradient: 'linear(to-r, secondary.main, primary.main)',
                                        boxShadow: 'xl',
                                    }}
                                    onClick={() => submit(setLoading)}
                                    loadingText='Enviando'
                                    type='submit'
                                    disabled={loading || edition.active_stage != '1-submit_open' || !teaserLink}
                                >
                                    Enviar
                                </Button>
                                <Button
                                    fontFamily={'poppins'}
                                    mt={8}
                                    w={'45%'}
                                    bgColor="#3d4758d6"
                                    color={'white'}
                                    _hover={{
                                        boxShadow: 'xl',
                                    }}
                                    onClick={() => team.first_stage.video_teaser_link ? window.open(team.first_stage.video_teaser_link, '_blank') : alert("Nenhum vídeo foi enviado")}
                                    loadingText='Enviando'
                                    disabled={loading || !team || !team.first_stage || !team.first_stage.video_teaser_link}
                                >
                                    Ver envio
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>

                </Flex>

                :
                <NotStarted w='100%' h='100%' />
            }
        </Flex>
    );

}
