import { useEffect, useState } from 'react';
import { Auth, API, Storage } from 'aws-amplify';

// Custom hook that handles change video teaser link
const useHandleSecondStage = () => {
    
    let [status, setStatus] = useState('idle');
    let [data, setData] = useState([]);
    let [imLink, setImLink] = useState('');
    let [valuationLink, setValuationLink] = useState('');
    let [file, setFile] = useState(null);

    useEffect(()=>{

        const fetchData = async () => {
            setStatus('fetching');
            try {
                let myInit = {}
                try {
                    myInit = { 
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                } catch (error) {
                    console.log(error)
                }
                let userEmail = (await Auth.currentAuthenticatedUser()).attributes.email
                let endp = "/2022/" + userEmail
                const result = await API.get("TEAMS", endp, myInit);
                setData(result);
                let team = result.team
                setImLink(team.second_stage.im_link ? team.second_stage.im_link : '')
                setValuationLink(team.second_stage.valuation_link ? team.second_stage.valuation_link : '')
                setStatus('idle');
            } catch (error) {
                console.log(error)
                setStatus('idle');
                if (error.response.status == 404) {
                    setData([]);
                } else {
                    alert('Erro ao carregar os dados, tente novamente mais tarde.');
                }
            }
        };

        fetchData();

    }, [])


    const handleChange = (e, typ) => {
        if (typ == 'im') {
            setImLink(e.target.value);
        } else if (typ == 'valuation') {
            setValuationLink(e.target.value);
        } else if (typ == "file") {
            setFile(e)
        }
    }


    const handleSubmit = async (type, setLoading) => {
        setLoading(true);
        
        let body = {
            login_email : data.team.login_email,
            challenge_year : 2022, //TODO: CHANGE HARDCODED
            update : {
                second_stage : {...data.team.second_stage}
            }
        }
        
        if (type == 'im') {
            body.update.second_stage.im_link = imLink;
        } else if (type == 'valuation') {
            body.update.second_stage.valuation_link = valuationLink;
        }

        try {
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }
            let response = await API.post('TEAMS', '/update', myInit);
            setLoading(false)
            alert('Envio atualizado com sucesso.')
            window.location.reload();
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }
    }

    const fileSubmit = async (setLoading) => {
        setLoading(true);
        let fileName = data.team.team_name + '_' + 'financial_model_2022' + '.pdf'
        try {
            const result = await Storage.put(fileName, file);
            alert("Envio realizado com sucesso! você pode reenviar quantas vezes desejar, apenas o ultimo envio será considerado.")
            setLoading(false)
        } catch (err) {
            setLoading(false)
            return alert("Erro ao realizar o upload do modelo financeiro, tente novamente mais tarde ou entre em contato através do email: tech@igcp.com.br")
        }
    }


    return [status, data, imLink, valuationLink, handleChange, handleSubmit, fileSubmit, file];    

}

export default useHandleSecondStage;