import React from 'react';
import { BiVideo, BiMoney, BiLogOut, BiBuildingHouse } from 'react-icons/bi';
import { Flex, Divider, Button } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import {FaChalkboardTeacher } from 'react-icons/fa';
import { Auth } from 'aws-amplify';

import logo from '../../assets/white_logo.png';

export default function SideBar() {

    let navigate = useNavigate();
    let location = useLocation();

    let path = location.pathname;
  
    return (
        <Flex w='250px' h='100%' bgColor='secondary.main' position='absolute' top={0} left={0} direction='column' alignItems='center' p={5} pt={10} zIndex={100}>
            <img src={logo} alt='logo' width='60px' height='auto' />
            <Divider mt={10} />

            <MenuButton active={path == '/first-stage'} Icon={BiVideo} name='Video Teaser' onclick={() => navigate('/first-stage')} />
            <MenuButton active={path == '/mentoring'} Icon={FaChalkboardTeacher} name='Mentoria' onclick={() => navigate('/mentoring')} />
            <MenuButton active={path == '/second-stage'} Icon={BiMoney} name='IM e Valuation' onclick={() => navigate('/second-stage')} />
            <MenuButton active={path == '/final'} Icon={BiBuildingHouse} name='Etapa Presencial' onclick={() => navigate('/final')} />

            <Divider mt={10} />

            <MenuButton name='Sair' 
                Icon={BiLogOut}
                onclick={
                    () => {
                        Auth.signOut()
                        .then(data => {
                            navigate('/')
                        })
                        .catch(err => console.log(err))
                    }
                } 
            />

        </Flex>
    );
}

function MenuButton({ name, onclick, Icon, active }) {

    return(
        <Flex
            h="45px"
            fontFamily={'heading'}
            align="center"
            borderRadius={5}
            pl={5}
            mt={4}
            w={"100%"}
            color="white"
            bgGradient={active ? "linear-gradient(to right, #616772, #3a588d)" : "transparent"}
            textAlign={'left'}
            cursor={'pointer'}
            _hover={{
                bgGradient: 'linear-gradient(to right, #616772, #3a588d87)',
            }}
            onClick={() => onclick()}
        >
            {Icon && <Icon size={25} style={{marginRight:15}} />}
            {name}
        </Flex>
    );
    
}