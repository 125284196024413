import { useState } from 'react';
import { Auth } from 'aws-amplify';


// Custom hook that handles form submit
const useHandleSignup = (initialState) => {
    
    const [err, setErr] = useState({...initialState});

    const login = async (values, setIsLoading, navigate) => {

        setIsLoading(true)

        // Validate form
        let newErr = {...initialState};
        let hasErr = false;

        if (values.email.length == 0 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
            newErr.email = 'Email inválido';
            hasErr = true;
        }
        if (values.password.length == 0) {
            newErr.password = 'Insira sua senha';
            hasErr = true;
        }
        if (values.password != values.confirm) {
            newErr.confirm = 'As senhas não conferem';
            hasErr = true;
        }

        // If there are errors, set the error state
        if (hasErr) {
            setIsLoading(false)
            return setErr({...newErr});
        } else {
            setErr({...initialState});
        }

        try {
            let response = await Auth.signUp({
                username: values.email,
                password: values.password,
                email: values.email
            })
            alert('Um código de confirmação foi enviado via e-mail. Verifique sua conta para prosseguir com o cadastro da equipe!')
            navigate('/confirm-signup')
            setIsLoading(false)
        } catch (err) {
            if (err.code === 'UsernameExistsException') {
                setErr({...newErr, email: 'Email já cadastrado'})
            } else if (err.code === 'InvalidParameterException' || err.code === 'InvalidPasswordException') {
                setErr({...newErr, confirm: 'A senha deve conter no mínimo 8 caracteres, letras minúsculas, maiúsculas, números e caracteres especiais'})
            }
            setIsLoading(false)
        }
        
    }

    return [err, login];    

}

export default useHandleSignup;