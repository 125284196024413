import { useState } from 'react';
import { Auth } from 'aws-amplify';


// Custom hook that handles form submit
const useConfirmSignup = (initialState) => {
    
    const [err, setErr] = useState({...initialState});

    const confirm = async (values, setIsLoading, navigate) => {

        setIsLoading(true)

        // Validate form
        let newErr = {...initialState};
        let hasErr = false;

        if (values.email.length == 0 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
            newErr.email = 'Email inválido';
            hasErr = true;
        }
        if (values.code.length !== 6) {
            newErr.code = 'Código inválido';
            hasErr = true;
        }

        if (hasErr) {
            setIsLoading(false)
            return setErr({...newErr});
        } else {
            setErr({...initialState});
        }

        try {
            let response = await Auth.confirmSignUp(values.email, values.code);
            console.log(response)
            navigate('/success')
            setIsLoading(false)
        } catch (err) {
            console.log(err.code)
            if (err.code == 'CodeMismatchException') {
                setErr({...newErr, code: 'Código inválido'})
            } else if (err.code == 'ExpiredCodeException') {
                setErr({...newErr, code: 'Código expirado, um novo código foi enviado para o email cadastrado'})
                Auth.resendSignUp(values.email)
            }
            setIsLoading(false)
        }
        
    }

    return [err, confirm];    

}

export default useConfirmSignup;