import { Container, Text, Heading, Stack } from '@chakra-ui/react'
import yellowQuote from '../../assets/yellowoutlinequote.svg'

export default function Footer() {

    return(
        <Container h='22vh' maxW='100%' p={0} bgColor='secondary.main' position='relative' overflow='hidden'>

            <Stack direction='column' w={['95%', '95%', '42%']} align='flex-start' justify='space-evenly' p={5} spacing={8} ml={5}>
                <Heading textAlign='left' fontSize={20} fontWeight={400} color='#ebebeb7d' fontFamily='Poppins'>
                    challenge@igcp.com.br
                </Heading>
                <Text textAlign='left' fontSize={14} fontWeight={400} color='#ebebeb7d' fontFamily='Poppins' style={{marginTop:10}}>
                    Av. Brigadeiro Faria Lima, 2277<br/>
                    6° andar São Paulo, SP, Brasil<br/>
                    CEP 01452-000<br/>
                    +55 11 3815-3533
                </Text>
            </Stack>

            <Text position='absolute' bottom={0} bgColor='#00000052' color='#8d8d8d' w='100%' fontSize={12} textAlign='center'>
                © Copyright 2022 igc partners. Todos os direitos reservados.
            </Text>

            <img 
                src={yellowQuote}
                style={{
                    height: 200,
                    position: 'absolute',
                    bottom: '15%',
                    right: '20%',
                }}
            />

            <img 
                src={yellowQuote}
                style={{
                    height: 200,
                    position: 'absolute',
                    bottom: '10%',
                    right: '18%',
                }}
            />

        </Container>
    );
    
}