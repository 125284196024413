
import { Heading, Flex, Text, Button, Select } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { BsSquareFill } from 'react-icons/bs'
import { useState } from 'react'

import { Header, SideBar, MultipleInput, NotStarted, Loading } from '../components'
import { useHandleMentoringStage } from '../hooks'

export default function Mentoring() {

    let navigate = useNavigate()

    let [loading, setLoading] = useState(false)
    let [status, {team, edition}, questions, mentoringTime, disableTime, setQuestions, setChosenTime, handleQuestionChange, sendQuestions, submitMentoringTime] = useHandleMentoringStage()

    return(
        <Flex minH='100vh' w='100%' p={10} position='relative' bgColor='#efe8d8' align='center' justify='flex-start' direction={'column'} pl={280} pt={85}>
            <SideBar navigate={navigate} />
            <Header title="Mentoria" />

            {status == 'fetching' ? <Loading h='100%' /> :

                edition && parseInt(edition.active_stage.split("-")[0]) >= 2 && team.status != 'eliminated_1' ?

                <Flex w='100%' h='auto' direction={['column', 'column', 'row']} align='flex-start' justify='space-between' pt={5} pb={2} pl={8}>

                    <Flex direction='column' w={['90%', '90%', '48%']} mb={10}>
                        <Flex direction="row" align="center" pl={4}>
                            <BsSquareFill color="#d5a741" />
                            <Heading fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} pl={4}>Perguntas</Heading>
                        </Flex>
                        <Flex bgColor="#ffffffa8" w="95%" mt={5} minH="220px" borderRadius={10} boxShadow="xl" p={5} direction="column" align='flex-start' justify='flex-start'>
                            <Text mb={6} fontSize={14} color="#8d8c8c" lineHeight="29px" mt={2} fontFamily="Poppins" textAlign={"justify"}>
                                Envie abaixo ao menos três perguntas da sua equipe. Elas podem abordar qualquer assunto pertinente, desde questoes sobre o case até questões sobre o processo, e serão endereçadas pelo mentor no dia e horário agendados para a mentoria.
                            </Text>
                            <MultipleInput handleChange={handleQuestionChange} setFields={setQuestions} fields={questions} label="Perguntas" disabled={false} />
                            <Button
                                fontFamily={'poppins'}
                                mt={8}
                                w={'100%'}
                                bgGradient="linear(to-r, secondary.main,primary.main)"
                                color={'white'}
                                _hover={{
                                    bgGradient: 'linear(to-r, secondary.main, primary.main)',
                                    boxShadow: 'xl',
                                }}
                                onClick={() => sendQuestions(setLoading)}
                                loadingText='Enviando'
                                type='submit'
                                disabled={loading || questions.length < 3 || questions[questions.length - 1] === '' || disableTime}
                            >
                                Salvar
                            </Button>
                        </Flex>
                    </Flex>

                    <Flex direction='column' w={['90%', '90%', '48%']} mb={10}>
                        <Flex direction="row" align="center" pl={4}>
                            <BsSquareFill color="#d5a741" />
                            <Heading fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} pl={4}>Agendamento da Mentoria</Heading>
                        </Flex>                    
                        <Flex bgColor="#ffffffa8" w="95%" mt={5} minH="220px" borderRadius={10} boxShadow="xl" p={5} direction="column">
                            <Text mb={6} fontSize={14} color="#8d8c8c" lineHeight="29px" fontFamily="Poppins" textAlign={"justify"}>
                                Escolha um dos horários disponibilizados para a mentoria. Para salvar é necessário ter enviado ao menos três perguntas.
                            </Text>
                            {team && team.mentoring_time ? 
                            
                                <Text mt={1} ml={2} fontWeight={600} fontSize={12} pb={1}>Horário Escolhido: {team.mentoring_time}</Text>
                            :    
                                <>
                                    <Text mt={1} ml={2} fontWeight={600} fontSize={12} pb={1}>Horário</Text>
                                    <Select disabled={disableTime} color={'gray.500'} fontSize={12} fontFamily='Poppins' w='100%' bgColor='white' name="prospect_other_originator" onChange={(e) => setChosenTime(e.target.value)}>
                                        {mentoringTime.filter(item => !item.taken).map((time, index) => (
                                            <option key={index} value={time.time}>{time.time}</option>
                                        ))}
                                    </Select>
                                    <Button
                                        fontFamily={'poppins'}
                                        mt={8}
                                        w={'100%'}
                                        bgGradient="linear(to-r, secondary.main,primary.main)"
                                        color={'white'}
                                        _hover={{
                                            bgGradient: 'linear(to-r, secondary.main, primary.main)',
                                            boxShadow: 'xl',
                                        }}
                                        onClick={() => submitMentoringTime(setLoading)}
                                        loadingText='Enviando'
                                        type='submit'
                                        disabled={loading || disableTime || !team.second_stage}
                                    >
                                        Salvar
                                    </Button>
                                </>
                        
                            }
                        </Flex>
                    </Flex>

                </Flex>

                :
                <NotStarted w='100%' h='100%' />
            }
        </Flex>
    );

}
