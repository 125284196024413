import { Flex, Heading } from '@chakra-ui/react'

// Loading component
export default function NotStarted({ w, h }) {
    return(
        <Flex w={w} h={h} direction='row' align='center' justify='center' pt={5} pb={2} pl={8} bgColor='#efe8d8'>
            <Heading fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} pl={4}>
                Etapa ainda não iniciada!
            </Heading>
        </Flex>
    );
}