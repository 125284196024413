import { useEffect, useState } from 'react';
import { Auth, API } from 'aws-amplify';

// Custom hook that handles change video teaser link
const useHandleMentoringStage = () => {
    
    let [status, setStatus] =                   useState('idle');
    let [data, setData] =                       useState([]);
    let [questions, setQuestions] =             useState(['']);
    let [mentoringTime, setMentoringTime] =     useState('');
    let [chosenTime, setChosenTime] =           useState('');
    let [disableTime, setDisableTime] =         useState(false);


    useEffect(()=>{

        const fetchData = async () => {

            setStatus('fetching');

            try {

                let myInit = {}
                try {
                    myInit = { 
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                } catch (error) {
                    console.log(error)
                }

                let userEmail = (await Auth.currentAuthenticatedUser()).attributes.email
                let endpoint = "/2022/" + userEmail
                let result = await API.get("TEAMS", endpoint, myInit);
                setData(result);
                
                let evaluator = result.team.evaluator.split("@")[0].split(".")[1]
                let schedules = await API.get("CHALLENGE_SCHEDULER", "/schedule/" + evaluator, myInit);
                setMentoringTime(schedules)
                setChosenTime(schedules[0].time)

                if (result && result.team.second_stage && result.team.second_stage.questions) {
                    setQuestions(result.team.second_stage.questions);
                }

                if (result && result.team.mentoring_time) {
                    setDisableTime(true);
                    setChosenTime(result.team.mentoring_time);
                }

                setStatus('idle');

            } catch (error) {
                console.log(error)
                setStatus('idle');
                if (error.response.status == 404) {
                    setData([]);
                } else {
                    alert('Erro ao carregar os dados, tente novamente mais tarde.');
                }
            }
        };

        fetchData();

    }, [])


    const sendQuestions = async (setLoading) => {

        setLoading(true)
        let body = {
            login_email : data.team.login_email,
            challenge_year : 2022, //TODO: CHANGE HARDCODED
            update : {
                second_stage : data.team.second_stage ? {...data.team.second_stage} : {},
            }
        }
        body.update.second_stage.questions = questions;

        try {
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }

            let response = await API.post('TEAMS', '/update', myInit);
            setLoading(false)
            alert('Perguntas da mentoria: Envio atualizado com sucesso.')
            window.location.reload();
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }
    }

    const submitMentoringTime = async (setLoading) => {
        setLoading(true)
        if (!data.team.second_stage || !data.team.second_stage.questions || data.team.second_stage.questions.length < 3) {
            setLoading(false)
            return alert("Você precisa enviar as perguntas da mentoria antes de agendar um horário.")
        }

        let body = {
            team: data.team,
            mentor: data.team.evaluator.split("@")[0].split(".")[1],
            time: chosenTime,
        }

        try {
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }
            let response = await API.post('CHALLENGE_SCHEDULER', '/schedule/assign', myInit);
            console.log(response)
            setLoading(false)
            alert('Horário agendado com sucesso.')
            window.location.reload();
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }  

    }
        
    const handleArrayInputChange = (e, index) => {
        const { value } = e.target;
        
        let newArray = [...questions];
        newArray[index] = value;
        setQuestions(newArray);
        
    }


    return [status, data, questions, mentoringTime, disableTime, setQuestions, setChosenTime, handleArrayInputChange, sendQuestions, submitMentoringTime];    

}

export default useHandleMentoringStage;