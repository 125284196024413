import { useEffect, useState } from 'react';
import { Auth, API } from 'aws-amplify';

// Custom hook that handles change video teaser link
const useHandleFirstStage = () => {
    
    let [status, setStatus] = useState('idle');
    let [data, setData] = useState([]);
    let [teaserLink, setTeaserLink] = useState('');


    useEffect(()=>{

        const fetchData = async () => {
            setStatus('fetching');
            try {
                let myInit = {}
                try {
                    myInit = { 
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                } catch (error) {
                    console.log(error)
                }
                let userEmail = (await Auth.currentAuthenticatedUser()).attributes.email
                let endp = "/2022/" + userEmail
                const result = await API.get("TEAMS", endp, myInit);
                setData(result);
                let team = result.team
                if (team && team.first_stage && team.first_stage.video_teaser_link) {
                    setTeaserLink(team.first_stage.video_teaser_link);
                }
                setStatus('idle');
            } catch (error) {
                console.log(error)
                setStatus('idle');
                if (error.resonse.status == 404) {
                    setData([]);
                } else {
                    alert('Erro ao carregar os dados, tente novamente mais tarde.');
                }
            }
        };

        fetchData();

    }, [])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setTeaserLink(value);
    }


    const sendVideoTeaser = async (setIsLoading) => {

        setIsLoading(true)

        let body = {
            login_email : data.team.login_email,
            challenge_year : 2022, //TODO: CHANGE HARDCODED
            update : {
                first_stage : data.team.first_stage ? {...data.team.first_stage} : {status:"submitted"},
            }
        }
        body.update.first_stage.video_teaser_link = teaserLink;

        try {

            let user = await Auth.currentAuthenticatedUser();
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }

            let response = await API.post('TEAMS', '/update', myInit);
            setIsLoading(false)
            alert('Video Teaser: Envio atualizado com sucesso.')
            window.location.reload();
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setIsLoading(false)
        }
        
    }

    return [status, data, teaserLink, handleChange, sendVideoTeaser];    

}

export default useHandleFirstStage;